@use 'libs/core/src/lib/theme/helper' as helper;

.fu-table {
  width: 100%;

  thead {
    &:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border-radius: var(--fu-radius-xs);
      background: var(--fu-table-bg);
      z-index: -1;
    }

    position: relative;
    font-size: 12px;
    color: var(--fu-table-color);
    text-align: left;
    font-weight: var(--fu-font-regular);
    z-index: 1;
  }

  th, td {
    padding: var(--fu-spacing-s) var(--fu-spacing-xs);
  }

  tr:nth-child(even) {
    background: rgba(245, 245, 245, 0.5);
  }
}

/**
Deprecated table classes
 */
.fu-legacy-table-header {
  display: grid;
  align-items: center;
  padding: var(--fu-spacing-s) 8px;
  font-size: 12px;
  line-height: 14px;
  color: var(--fu-table-color);
  background: var(--fu-table-bg);
  border-radius: 10px;
}

.fu-legacy-table-column {
  display: flex;
  align-items: center;
}

.fu-legacy-table-row {
  display: grid;
  padding: 12px var(--fu-spacing-xs);

  &:nth-child(even) {
    background: rgba(245, 245, 245, 0.5);
  }
}

.fu-legacy-table {
  background: var(--fu-body-bg);
  text-align: center;
  border-radius: 16px;
  padding: var(--fu-spacing-xl);

  &__icon {
    font-size: 32px;
    margin-bottom: var(--fu-spacing-xl);
  }

  &__subtitle {
    font-size: 16px;
    line-height: 19px;
    margin: var(--fu-spacing-xs) 0 60px 0
  }

  &__content {
    @include helper.flex-column();
    overflow-x: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    &__line {
      display: grid;
      text-align: start;
      align-items: center;
      padding: 12px var(--fu-spacing-xs);
      font-size: 16px;
      line-height: 19px;
      min-width: 800px;

      &__center {
        display: flex;
        align-items: center;
      }

      &__end {
        text-align: end;
      }

      &:nth-child(odd) {
        background: rgba(245, 245, 245, 0.5);
      }

      &--header {
        padding: var(--fu-spacing-s) var(--fu-spacing-xs);
        background: var(--fu-grey-7);
        color: var(--fu-grey-5);
        font-size: 12px;
        line-height: 14px;
        border-radius: 10px;
      }
    }
  }
}

.fu-member-table {
  .header {
    @include helper.flex-space-between();
    align-items: center;
    padding: var(--fu-spacing-m);
    margin: calc(-1 * var(--fu-content-padding-side));
    gap: var(--fu-spacing-m);
    box-shadow: 0 10px 20px 0 rgba(29, 30, 37, 0.05);

    .emblem {
      width: var(--fu-icon-font-size-xl);
      height: var(--fu-icon-font-size-xl);
    }
  }

  .list {
    padding-top: var(--fu-spacing-xxl);

    .fu-challenge-list-item--active {
      margin-left: calc(-1 * (var(--fu-content-padding-side)));
      padding-left: var(--fu-spacing-s);
    }

    .list--item {
      grid-template-columns: 1fr;

      .image {
        width: var(--fu-icon-font-size-l);
        height: var(--fu-icon-font-size-l);
        border-radius: 50%;
        object-fit: cover;
      }
    }
  }
}

@media screen and (max-width: 420px) {
  .fu-legacy-table__content__line {
    min-width: 700px;
    font-size: 14px;

    &--header {
      font-size: 10px;
    }
  }
}

