.fu-bell-container{
  position: relative;
  height: 24px;

  .fu-point--bell{
    left: 15px;
    top: -2px;
  }
}

.fu-point {
  position: absolute;
  box-sizing: content-box;
  top: 0;
  right: 2px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: var(--fu-light-red);
  border: 2px solid var(--fu-body-bg);
}
