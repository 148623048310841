.fu-icon {
  &--primary {
    color: var(--fu-icon-primary-color);
  }

  &--size-l {
    font-size: 25px;
  }

  &--premium-corporate {
    color: var(--fu-color-premium-corporate);
  }

  &--premium-private {
    color: var(--fu-color-premium-private);
  }
}

.fu-default-icon {
  font-size: var(--fu-icon-font-size-regular);
}

.fu-icon-xs {
  font-size: var(--fu-icon-font-size-xs);
}

.fu-icon-s {
  font-size: var(--fu-icon-font-size-s);
}

.fu-icon-l {
  font-size: var(--fu-icon-font-size-l);
}

.fu-icon-m {
  font-size: var(--fu-icon-font-size-m);
}

.fu-icon-mx {
  font-size: var(--fu-icon-font-size-mx);
}

.fu-icon-mxs {
  font-size: var(--fu-icon-font-size-mxs);
}

.fu-icon-l {
  font-size: var(--fu-icon-font-size-l);
}

.fu-icon-xl {
  font-size: var(--fu-icon-font-size-xl);
}

.fu-icon-3xl {
  font-size: var(--fu-icon-font-size-3xl);
}

.fu-icon-4xl {
  font-size: var(--fu-icon-font-size-4xl);
}

.fu-premium-icon {
  font-size: var(--fu-premium-icon-size);
}

.fu-input-icon-size {
  font-size: var(--fu-input-icon-size);
}

.fu-icon-circle, .fu-icon-bubble {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

/* new design */
.fu-icon-circle {
  --fu-circle-size: 46px;
  --fu-icon-color: var(--fu-color-primary);

  font-size: var(--fu-icon-font-size-regular);
  background-color: var(--fu-circle-bg-color);
  color: var(--fu-icon-color);
  height: var(--fu-circle-size);
  width: var(--fu-circle-size);
  min-width: var(--fu-circle-size);

  &--theme-fit-score {
    --fu-icon-color: var(--fu-color-fit-score);
    --fu-circle-bg-color: rgba(var(--fu-color-fit-score-rgb), 0.1);
  }

  &--theme-challenge {
    --fu-icon-color: var(--fu-color-challenge);
    --fu-circle-bg-color: rgba(var(--fu-color-challenge-rgb), 0.1);
  }

  &--theme-activity {
    // TODO: check if this will be activity color
    --fu-icon-color: var(--fu-light-purple);
    --fu-circle-bg-color: rgba(var(--fu-light-purple-rgb), 0.1);
  }

  &--primary-bg {
    --fu-circle-bg-color: var(--fu-color-primary);
    --fu-icon-color: var(--fu-body-bg);
  }

  &--body-color {
    --fu-icon-color: var(--fu-body-color);
  }

  &--body-bg {
    --fu-icon-color: var(--fu-body-bg);
  }

  &--light {
    --fu-icon-color: var(--fu-pure-white);
    --fu-circle-bg-color: rgba(var(--fu-pure-white-rgb), 0.3);
  }

  &--mild-grey {
    --fu-icon-color: var(--fu-pure-white);
    --fu-circle-bg-color: var(--fu-mild-grey);

    &--disabled {
      --fu-icon-color: var(--fu-grey-5);
    }
  }

  &--white-bg {
    --fu-circle-bg-color: var(--fu-pure-white);
  }

  &--grey-bg {
    --fu-circle-bg-color: var(--fu-grey-14);
  }

  &--m {
    --fu-circle-size: 40px;
    --fu-icon-font-size-regular: 18px;
  }

  &--s {
    --fu-circle-size: 36px;
    --fu-icon-font-size-regular: 18px;
  }

  &--xs {
    --fu-circle-size: 24px;
    --fu-icon-font-size-regular: 12px;
  }
}

.fu-icon-bubble {
  --fu-icon-bubble-background: var(--fu-color-primary);
  --fu-icon-bubble-color: var(--fu-pure-white);
  --fu-icon-bubble-size: 64px;
  --fu-icon-bubble-font-size: 30px;

  font-size: var(--fu-icon-bubble-font-size);
  color: var(--fu-icon-bubble-color);
  background-color: var(--fu-icon-bubble-background);
  height: var(--fu-icon-bubble-size);
  width: var(--fu-icon-bubble-size);
}

.fu-header-icon {
  font-size: 24px;
  cursor: pointer;

  &--grey {
    color: var(--fu-grey-5);
  }

  &--light-red {
    color: var(--fu-light-red);
  }

  &--light-grey {
    color: var(--fu-grey-3);
  }
}

.fu-grey-icon {
  color: var(--fu-grey-3);
  font-size: var(--fu-icon-font-size-regular);
}

.fu-blue-icon {
  color: var(--fu-blue);
  font-size: var(--fu-icon-font-size-regular);
}

.fu-header-icons-container {
  display: flex;
  align-items: center;
  gap: var(--fu-spacing-s);
}

.fu-header-icon-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--fu-header-bg);
  width: 46px;
  height: 46px;
  border-radius: 50%;

  &:hover {
    cursor: pointer;
  }

  &--active {
    background-color: var(--fu-body-color);
    color: var(--fu-body-bg);
  }

  &--with-title {
    gap: var(--fu-spacing-xs);
    padding: var(--fu-spacing-xs) var(--fu-spacing-m);
    border-radius: var(--fu-radius-3xl);
    width: fit-content;

    ion-icon {
      font-size: var(--fu-icon-font-size-regular);
    }

    p {
      margin: 0;
      font-size: var(--fu-font-size-xs);
      font-weight: var(--fu-font-semi-bold);
    }
  }
}

.fu-header-text-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  gap: var(--fu-spacing-xs);
  height: 46px;
  background-color: var(--fu-header-bg);
  padding: 0 var(--fu-spacing-xs) 0 var(--fu-spacing-m);
  border-radius: 60px;

  &--with-icon {
    padding: 0 var(--fu-spacing-xs) 0 var(--fu-spacing-m);
  }

  &--active {
    background-color: var(--fu-body-color);
    color: var(--fu-body-bg);
  }
}

.fu-icon-shadow {
  filter: drop-shadow(0px 0 40px rgba(var(--fu-color-primary-rgb), 0.40));
}
