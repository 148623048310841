.fu-modal {
  --border-radius: var(--fu-radius-s);
  --height: auto;
  --max-width: 353px;
  --backdrop-opacity: var(--fu-modal-backdrop-opactiy, 0.32) !important;

  &--event-response {
    --max-width: 358px;
  }

  &--medium {
    --max-width: 800px;
    --height: 500px;
  }

  &--centered-button {
    .fu-button--legacy {
      width: fit-content;
      margin: auto;
    }
  }
}

@media screen and (min-width: 767px) {
  .fu-subscription-modal {
    --height: 700px;
    --width: 700px;
    --border-radius: var(--fu-radius-l);
  }

  .fu-modal-default {
    --border-radius: var(--fu-radius-l);

    &--small {
      --height: 400px;
      --max-width: 353px;
      --backdrop-opacity: var(--fu-modal-backdrop-opactiy, 0.32) !important;
    }

    &--medium {
      --height: 700px;
    }

    &--ml {
      --height: 750px;
    }

    &--large {
      --height: 900px;
    }
  }

  .fu-change-password-modal {
    --height: 650px;
  }
}

.fu-pinboard-modal {
  --height: 680px;
}

.fu-sheet-modal.ios {
  --border-radius: var(--fu-radius-l);
}

.fu-sheet-modal {
  --border-radius: var(--fu-radius-l) var(--fu-radius-l) 0 0;

  &--colored {
    --ion-color-step-350: var(--fu-body-color);
  }

  &::part(handle) {
    margin: 10px auto;
  }
}

.fu-video-program-player-dialog {
  --width: 100%;
  --height: 100%;

  .modal-wrapper {
    max-width: unset !important;
  }
}

.fu-report-modal {
  --max-width: 353px;
  --max-height: 590px;
  --min-height: 590px;
  --border-radius: var(--fu-radius-s);
  --backdrop-opacity: var(--fu-modal-backdrop-opactiy, 0.32) !important;

  .fu-button {
    width: 100%;
  }

  @media screen and (max-height: 560px) and (max-width: 354px) {
    --border-radius: 0;
  }
}

@media screen and (min-width: 1000px) {
  .fu-video-modal:not(.ios) {
    --height: 560px;
    --width: 1000px;
  }
}

@media only screen and (min-width: 768px) and (min-height: 768px) {
  .fu-video-modal {
    --width: 700px;
    --height: 400px;
  }
}

@media only screen and (min-width: 768px) and (min-height: 600px) {
  .fu-video-modal {
    --width: 700px;
    --height: 400px;
  }
}

@media only screen and (max-width: 767px) and (min-height: 400px) {
  .fu-video-modal {
    --width: 500px;
    --height: 280px;
  }
}

@media only screen and (max-width: 499px) and (min-height: 400px) {
  .fu-video-modal {
    --width: 350px;
    --height: 195px;
  }
}

@media screen and (max-width: 425px) {
  .fu-sheet-modal {
    --min-width: 100%;
  }
}
