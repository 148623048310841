.fu-title {
  font-weight: var(--fu-font-bold);
  margin: 0;
  line-height: normal;

  &--regular {
    font-weight: var(--fu-font-regular);
  }

  &--semi-bold {
    font-weight: var(--fu-font-semi-bold);
  }

  // TODO: don't use - let's stay modular with semi-bold + font-size
  &--4xs-semi-bold {
    font-size: 18px;
    font-weight: var(--fu-font-semi-bold);
  }

  &--4xs {
    font-size: 18px;
  }

  &--3xs {
    font-size: 20px;
  }

  &--xxs {
    font-size: 24px;
  }

  // deprecated
  &--detail {
    font-size: 32px;
  }

  &--detail-page {
    font-size: 26px;
  }

  &--xs {
    font-size: 30px;
  }

  &--s {
    font-size: 40px;
  }

  &--small {
    font-size: 46px;
  }

  &--medium {
    font-size: 44px;
  }

  &--large {
    font-size: 60px;
  }

  &--grey {
    color: var(--fu-grey-5);
  }
}

.fu-subtitle {
  font-weight: 400;
  font-size: 14px;
  color: var(--fu-grey-5);

  &--light {
    color: var(--fu-grey-3);
  }

  &--light-grey {
    color: var(--fu-grey-16);
  }

  &--mild-grey {
    color: var(--fu-mild-grey);
  }

  &--dark-grey {
    color: var(--fu-grey-20);
  }

  &--body-color {
    color: var(--fu-body-color);
  }
}

.fu-sub-heading {
  font-size: 16px;
  font-weight: var(--fu-font-semi-bold);

  &--weight-medium {
    font-weight: var(--fu-font-medium);
  }

  &--xs {
    font-size: 10px;
  }

  &--mxs {
    font-size: 12px;
  }

  &--small {
    font-size: 13px;
  }

  &--medium {
    font-size: 14px;
  }

  &--ml {
    font-size: 15px;
  }

  &--large {
    font-size: 16px;
  }

  &--l {
    font-size: 17px;
  }

  &--xl {
    font-size: 18px;
  }

  &--mxl {
    font-size: 22px;
  }

  &--xxl {
    font-size: 24px;
  }

  &--3xl {
    font-size: 26px;
  }
}

.fu-description,
.fu-weight {
  &--lighter {
    font-weight: var(--fu-font-light);
  }

  &--normal {
    font-weight: var(--fu-font-regular);
  }

  &--regular {
    font-weight: var(--fu-font-medium);
  }

  &--medium {
    font-weight: var(--fu-font-semi-bold);
  }

  &--bold {
    font-weight: var(--fu-font-bold);
  }

  &--extra-bold {
    font-weight: var(--fu-font-extra-bold);
  }
}

.fu-font-size {
  &--s {
    font-size: var(--fu-font-size-xs);
  }
}

.fu-title {
  &--light {
    font-size: 20px;
  }
}

.fu-description {
  font-size: var(--fu-font-size-xs);

  &--s {
    font-size: var(--fu-font-size-xs);
  }

  &--xs {
    font-size: var(--fu-font-size-xxs);
  }
}

.fu-box-description {
  font-size: 13px;
  color: rgba(var(--fu-body-color-rgb), 0.8);
}

.fu-description, .fu-tiny-hint, .fu-asterisk-hint {
  color: var(--fu-grey-5);
}

.fu-asterisk-hint {
  font-size: 14px;
}

.fu-tiny-hint {
  font-weight: var(--fu-font-regular);
  font-size: 13px;
  line-height: 18px;
}

.fu-title-with-icon-container {
  display: flex;
  gap: 10px;
  align-items: center;

  ion-icon {
    font-size: 24px;
  }
}

@media screen and (max-width: 940px) {
  .fu-setup-container {
    .fu-title {
      &--large {
        font-size: 50px;
      }
    }
  }
}

@media screen and (max-width: 760px) {
  .fu-setup-container {
    .fu-title {
      &--large {
        font-size: 60px;
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .fu-title, .fu-setup-container .fu-title {
    &--large {
      font-size: 50px;
    }
  }
}

